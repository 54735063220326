
import {
  infinityScrollTable,
  helperMixin,
  filterHelper,
  positionFilter
} from "@/mixins";
import { SailorPaginate, SailorPaginateFilter } from "@/models/sailor";
import { defineComponent } from "vue";
import sailorHttp from "@/http/sailor";
import ActionTableColumn from "@/components/Column/ActionTableColumn.vue";
import BaseTable from "@/components/Base/BaseTable.vue";
import BaseTableRow from "@/components/Base/BaseTableRow.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import { Default } from "@/enums";
import AvatarImg from "@/components/AvatarImg.vue";
import DropdownItem from "@/components/Dropdown/DropdownItem.vue";
import FilterModalContent from "@/components/FilterModalContent.vue";
import companyHttp from "@/http/company";
import BaseFormGroup from "@/components/Base/BaseFormGroup.vue";
import BaseInput from "@/components/Base/BaseInput.vue";

export default defineComponent({
  components: {
    ActionTableColumn,
    BaseTable,
    BaseTableRow,
    BaseTableColumn,
    AvatarImg,
    DropdownItem,
    FilterModalContent,
    BaseFormGroup,
    BaseInput
  },
  mixins: [
    helperMixin,
    filterHelper<SailorPaginateFilter>(),
    positionFilter<SailorPaginateFilter>(),
    infinityScrollTable<SailorPaginate, SailorPaginateFilter>(sailorHttp(), {
      position_in: []
    })
  ],
  computed: {
    companyHttp
  }
});
