
import BaseButtonPrimary from "@/components/Base/BaseButtonPrimary.vue";
import BaseModal from "@/components/Base/BaseModal.vue";
import { helperMixin } from "@/mixins";
import { defineComponent } from "vue";
import contentTool from "@/store/contentTool";
import BaseButtonDanger from "@/components/Base/BaseButtonDanger.vue";

export default defineComponent({
  emits: ["submit", "update:filter", "update:submittedFilter", "hide", "reset"],
  mixins: [helperMixin],
  components: { BaseModal, BaseButtonPrimary, BaseButtonDanger },
  props: {
    title: String,
    exceptKeysForFilterCount: {
      type: Array,
      default: ["search", "page"]
    },
    filter: {
      type: Object
    },
    submittedFilter: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
      showModal: false,
      offFilterClickEvent: () => {}
    };
  },
  mounted() {
    this.offFilterClickEvent = this.pubSub.on(
      this.EventNames.FilterButtonClick,
      () => (this.showModal = true)
    );
  },
  beforeUnmount() {
    this.offFilterClickEvent();
  },
  computed: {
    contentTool
  },
  methods: {
    async reset() {
      this.$emit("update:submittedFilter", {});
      
      await this.$nextTick();

      this.$emit("reset");
      this.showModal = false;
      this.contentTool.state.filterCount = 0;
    },
    calculateFilterCount() {
      const keys = Object.keys(this.submittedFilter);

      this.contentTool.state.filterCount = 0;

      for (let i = 0; i != keys.length; i++) {
        if (this.exceptKeysForFilterCount.indexOf(keys[i]) === -1) {
          if (Array.isArray(this.submittedFilter[keys[i]])) {
            this.contentTool.state.filterCount += this.submittedFilter[
              keys[i]
            ].length;
          } else if (this.submittedFilter[keys[i]]) {
            this.contentTool.state.filterCount += 1;
          }
        }
      }
    },
    async submit() {
      this.$emit("update:submittedFilter", { ...this.filter });
      this.$emit("submit");

      await this.$nextTick();

      this.showModal = false;
      this.calculateFilterCount();
    }
  }
});
