import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, withModifiers as _withModifiers, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_base_table_column = _resolveComponent("base-table-column")!
  const _component_base_table_row = _resolveComponent("base-table-row")!
  const _component_avatar_img = _resolveComponent("avatar-img")!
  const _component_base_table = _resolveComponent("base-table")!
  const _component_base_form_group = _resolveComponent("base-form-group")!
  const _component_base_input = _resolveComponent("base-input")!
  const _component_filter_modal_content = _resolveComponent("filter-modal-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_base_table, {
      colspan: "6",
      onLoadMore: _ctx.loadMore,
      loading: _ctx.loading,
      hasMore: _ctx.hasMore,
      noDataAvailable: _ctx.noDataAvailable,
      noResultFound: _ctx.noResultFound,
      hover: ""
    }, {
      header: _withCtx(() => [
        _createVNode(_component_base_table_row, null, {
          default: _withCtx(() => [
            _createVNode(_component_base_table_column, { type: "label" }),
            _createVNode(_component_base_table_column, {
              type: "label",
              class: "pl-0"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.fullname")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.company.name")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.user.email")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.job.position_id")), 1)
              ]),
              _: 1
            }),
            _createVNode(_component_base_table_column, { type: "label" }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.trans("layout.crew.join_date")), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      default: _withCtx(() => [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row) => {
          return (_openBlock(), _createBlock(_component_base_table_row, {
            key: row.id,
            onClick: _withModifiers(($event: any) => (
        _ctx.useRouter().push({
          name: _ctx.RouteNames.SailorDetail,
          params: {
            id: row.id
          }
        })
      ), ["stop"])
          }, {
            default: _withCtx(() => [
              _createVNode(_component_base_table_column, { class: "w-0" }, {
                default: _withCtx(() => [
                  _createVNode(_component_avatar_img, {
                    src: row.avatar_link,
                    alt: `${row.fullname}_avatar`
                  }, null, 8, ["src", "alt"])
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, { class: "pl-0" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.fullname), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.company_name), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.email), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(row.position), 1)
                ]),
                _: 2
              }, 1024),
              _createVNode(_component_base_table_column, null, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.formatDate(row.join_date)), 1)
                ]),
                _: 2
              }, 1024)
            ]),
            _: 2
          }, 1032, ["onClick"]))
        }), 128))
      ]),
      _: 1
    }, 8, ["onLoadMore", "loading", "hasMore", "noDataAvailable", "noResultFound"]),
    _createVNode(_component_filter_modal_content, {
      title: _ctx.trans('layout.filter_crew'),
      filter: _ctx.filter,
      "onUpdate:filter": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.filter) = $event)),
      submittedFilter: _ctx.submittedFilter,
      "onUpdate:submittedFilter": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.submittedFilter) = $event)),
      onSubmit: _cache[5] || (_cache[5] = ($event: any) => (_ctx.reload())),
      onReset: _cache[6] || (_cache[6] = ($event: any) => (_ctx.resetAll(true))),
      onHide: _cache[7] || (_cache[7] = ($event: any) => (_ctx.resetAll()))
    }, {
      default: _withCtx(() => [
        _createVNode(_component_base_form_group, {
          "select-key-value": "id",
          "select-key-text": "name",
          resourceHttp: _ctx.companyHttp,
          label: _ctx.trans('layout.company.name'),
          modelValue: _ctx.filter.company_id,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filter.company_id) = $event)),
          inputType: "select",
          ref: "formGroupCompany"
        }, null, 8, ["resourceHttp", "label", "modelValue"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.job.position_id'),
          items: _ctx.selectionDataToBaseItem(_ctx.selectionStore.state.job_position),
          inputType: "multi-select",
          modelValue: _ctx.filter.position_in,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filter.position_in) = $event)),
          ref: "formGroupPosition"
        }, null, 8, ["label", "items", "modelValue"]),
        _createVNode(_component_base_form_group, {
          label: _ctx.trans('layout.crew.year'),
          inputType: "custom"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_base_input, {
              modelValue: _ctx.filter.year,
              "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.filter.year) = $event)),
              onKeyup: _ctx.yearKeyup
            }, null, 8, ["modelValue", "onKeyup"])
          ]),
          _: 1
        }, 8, ["label"])
      ]),
      _: 1
    }, 8, ["title", "filter", "submittedFilter"])
  ], 64))
}