
import ActionToolDropdown from "@/components/ActionToolDropdown.vue";
import BaseTableColumn from "@/components/Base/BaseTableColumn.vue";
import { actionToolDropdown, helperMixin } from "@/mixins";
import { defineComponent, PropType } from "vue";

export default defineComponent({
  mixins: [helperMixin, actionToolDropdown],
  components: { BaseTableColumn, ActionToolDropdown },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String as PropType<import("@/types").BaseTableColumnType>,
      default: "data",
    },
  },
});
