import { createInstance } from "@/lib/fn";
import BaseResourceHttp, { Excepts } from "@/lib/resourceHttp";
import { CompanyPaginate, CompanySave } from "@/models/company";

class CompanyHttp extends BaseResourceHttp<CompanyPaginate, CompanySave, CompanySave> {
    protected ignoreMethods: Excepts[] = ['create', 'delete', 'update'];

    protected baseURL(): string {
        return "company";
    }
}

export default createInstance<CompanyHttp>(CompanyHttp);