import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_action_tool_dropdown = _resolveComponent("action-tool-dropdown")!
  const _component_base_table_column = _resolveComponent("base-table-column")!

  return (_ctx.type === 'data')
    ? (_openBlock(), _createBlock(_component_base_table_column, {
        key: 0,
        class: "col"
      }, {
        default: _withCtx(() => [
          (_ctx.show)
            ? (_openBlock(), _createBlock(_component_action_tool_dropdown, {
                key: 0,
                onEdit: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('edit'))),
                onDelete: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('delete'))),
                "edit-route": _ctx.editRoute,
                withEdit: _ctx.withEdit,
                withDelete: _ctx.withDelete
              }, {
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default", {}, undefined, true)
                ]),
                _: 3
              }, 8, ["edit-route", "withEdit", "withDelete"]))
            : _createCommentVNode("", true)
        ]),
        _: 3
      }))
    : (_openBlock(), _createBlock(_component_base_table_column, {
        key: 1,
        class: "col",
        type: "label"
      }))
}