import { PaginateResponse } from '@/interfaces'
import { createInstance } from '@/lib/fn'
import BaseResourceHttp, { Excepts } from '@/lib/resourceHttp'
import { SailorPaginate, SailorPaginateFilter } from '@/models/sailor'

class SailorHttp extends BaseResourceHttp<SailorPaginate, any, any> {
  protected ignoreMethods: Excepts[] = ["create", "delete", "update"];

  protected baseURL(): string {
    return "sailor";
  }

  paginate(
    filter?: SailorPaginateFilter
  ): Promise<PaginateResponse<SailorPaginate[]>> {
    return super.paginate({
      ...filter,
      position_in: filter?.position_in?.join(","),
    } as SailorPaginateFilter);
  }
}

export default createInstance<SailorHttp>(SailorHttp);
